.card-ranking-home {
    @apply rounded-lg shadow-sm bg-[#FFFFFF90] bg-opacity-50 backdrop-filter backdrop-blur  iphoneSE:w-[20rem] ipad:w-[450px] h-[300px] overflow-hidden p-3 flex flex-col justify-between;
}

.card-ranking-map {
    @apply rounded-lg shadow-sm bg-[#FFFFFF90] bg-opacity-50 backdrop-filter backdrop-blur  iphoneSE:w-[20rem] ipad:w-[420px] p-3 flex flex-col justify-between;
}

.card-ranking-map img {
    @apply w-full h-[200px] object-cover;
}
